import http, { BASE_URL_API_ECOMMERCE } from "./Http";

const getMakes = async ({ dlid = "", region = "USA", catalog = "motor" }) => {
    const res = await http.get(
        `${BASE_URL_API_ECOMMERCE}/makes?dealerId=${dlid}&region=${region}&catalog=${catalog}`
    );
    return res.data.data;
};

const getYears = async ({
    dlid = "",
    makeId = "",
    region = "USA",
    catalog = "",
    trim = "",
    engine = "",
}) => {
    const res = await http.get(
        `${BASE_URL_API_ECOMMERCE}/years/makes/${makeId}?dealerId=${dlid}&region=${region}&catalog=${catalog}&allYears&trim=${trim}&engine=${engine}&showAllYears`
    );
    return res.data.data;
};

const getModels = async ({
    dlid = "",
    makeId = "",
    yearId = "",
    region = "USA",
    catalog = "",
    trim = "",
    engine = "",
}) => {
    const res = await http.get(
        `${BASE_URL_API_ECOMMERCE}/models/makes/${makeId}/years/${yearId}?dealerId=${dlid}&region=${region}&catalog=${catalog}&trim=${trim}&engine=${engine}`
    );
    return res.data.data;
};

const getTrims = async ({
    dlid = "",
    makeId = "",
    yearId = "",
    modelId = "",
    region = "USA",
    catalog = "",
    trim = "",
    engine = "",
}) => {
    const res = await http.get(
        `${BASE_URL_API_ECOMMERCE}/trims/makes/${makeId}/years/${yearId}/models/${modelId}?dealerId=${dlid}&region=${region}&catalog=${catalog}&trim=${trim}&engine=${engine}`
    );
    return res.data.data;
};

const getEngines = async ({
    dlid = "",
    makeId = "",
    yearId = "",
    modelId = "",
    trimId = "",
    region = "USA",
    catalog = "",
    trim = "",
    engine = "",
}) => {
    const res = await http.get(
        `${BASE_URL_API_ECOMMERCE}/engines/makes/${makeId}/years/${yearId}/models/${modelId}/trims/${trimId}?dealerId=${dlid}&region=${region}&catalog=${catalog}&trim=${trim}&engine=${engine}`
    );
    return res.data.data;
};

const VehicleService = {
    getMakes,
    getYears,
    getModels,
    getTrims,
    getEngines,
};

export default VehicleService;
